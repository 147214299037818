import {
  getOngoingOrUpcomingSchedule,
  sortSchedules,
  SCOPE_TYPES
} from "@shared/lib/schedule-utils"
import { toDate } from "@/lib/date-utils"
import i18n, { setLocale } from "@/store/i18n"

export const VIEW_TYPES = {
  WEEK: "week",
  MONTH: "month"
}
export const INITIAL_DESKTOP_VIEW = VIEW_TYPES.MONTH
export const INITIAL_MOBILE_VIEW = VIEW_TYPES.WEEK

export const ANIMATION_DIRECTIONS = {
  FORWARD: "forward",
  BACKWARD: "backward"
}
export const INDEXED_DATE_FORMAT = "YYYY-MM-DD"

export const formatDate = date => toDate(date, INDEXED_DATE_FORMAT)

export const CONTEXTS = {
  GLOBAL: {
    id: "global",
    title: ""
  },
  SUBSCRIBER: {
    id: "subscriber",
    title: i18n.t("lib.scheduleUtil.forYou")
  }
}

export const getLastSchedule = schedules => {
  const sortedSchedules = sortSchedules(schedules)

  return sortedSchedules[sortedSchedules.length - 1]
}

export { sortSchedules, getOngoingOrUpcomingSchedule }

export const dayOfWeekInSchedule = language => {
  setLocale(language)

  return [
    i18n.t("lib.scheduleUtil.sunday"),
    i18n.t("lib.scheduleUtil.monday"),
    i18n.t("lib.scheduleUtil.tuesday"),
    i18n.t("lib.scheduleUtil.wednesday"),
    i18n.t("lib.scheduleUtil.thursday"),
    i18n.t("lib.scheduleUtil.friday"),
    i18n.t("lib.scheduleUtil.saturday")
  ]
}

export { SCOPE_TYPES }
